import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { getRootUrl } from 'commons/utils/getRootUrl';
import { logUserAction } from 'redux-stores/actions';
import { LogUserEventType } from 'redux-stores/enums/logUserEventType.enum';
import { selectUser, setActiveRole } from 'redux-stores/features/authSlice';
import { AppDispatch } from 'redux-stores/store';

export const UserRoleSelectionDialog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<UserRolesEnum | ''>('');
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent<UserRolesEnum | ''>): void => {
    if (event.target.value) {
      setSelectedRole(event.target.value as UserRolesEnum);
    }
  };

  useEffect(() => {
    if (user && user.userName && user.isAuthenticated && !user.activeRole) {
      setIsDialogOpen(true);
    }
  }, [user]);

  const handleClose = (): void => {
    if (selectedRole) {
      dispatch(setActiveRole(selectedRole));
      dispatch(
        logUserAction({
          action: LogUserEventType.LOGIN,
          activeRole: selectedRole,
          time: new Date(),
        }),
      );
      const url = getRootUrl(selectedRole);
      navigate(url);
      setIsDialogOpen(false);
    } else {
      setIsDialogOpen(true);
    }
  };
  return (
    <Dialog open={isDialogOpen} fullWidth>
      <DialogTitle>Role Selection</DialogTitle>
      <DialogContent>
        <Box>
          <FormControl fullWidth>
            <Select
              id="demo-dialog-select"
              value={selectedRole}
              onChange={handleChange}
              input={<OutlinedInput />}
            >
              {user.roles
                .filter((role) => role !== 'default-roles-wms')
                .map((role) => (
                  <MenuItem key={role} value={role}>
                    {capitalize(
                      role.replace('_', ' ').replace('-', ' ').trim(),
                    )}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" fullWidth>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
