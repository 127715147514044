import { FileImportType, ReplenishType } from 'commons/enums';
import { DashboardActiveLocation, ProductDetail } from 'commons/types';
import { IFileImport } from 'commons/types/fileImport.interface';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  BinListResponse,
  BulkPrioritizeResProps,
  IPostCreateBinRequest,
  IRemoveReplenishQueueResProps,
  OrderListResponse,
  ReplenishListResponse,
  StockTransferListResponse,
  UserAPIResponse,
  UserListResponse,
} from './types';
import {
  PackingActiveOrderSummariesResponse,
  PackingShiftSummariesResponse,
  PickingActiveOrderSummariesResponse,
  PickingShiftSummariesResponse,
} from './types/get-summaries.type';
import { IBulkUpdateRetryResProps } from './types/update-retry.interface';

class SwipeRxWmsApiDashboard extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.DASHBOARD);
    this.basePath = `/${this.apiResource}`;
  }

  async getOrderListPaginated(
    queries: Record<string, string | number>,
  ): Promise<OrderListResponse> {
    const result = await this.get(`${this.basePath}/order-list`, queries);
    if (result && result.data) {
      return result.data as OrderListResponse;
    }
    return [];
  }

  async getPickingSummariesActiveOrders(
    location: number | undefined,
  ): Promise<PickingActiveOrderSummariesResponse | null> {
    const result = await this.get(
      `${this.basePath}/picking-summaries/active-orders`,
      {
        location,
      },
    );
    if (result && result.data) {
      return result.data as PickingActiveOrderSummariesResponse;
    }
    return null;
  }

  async getPickingSummariesShift(
    location: number | undefined,
  ): Promise<PickingShiftSummariesResponse | null> {
    const result = await this.get(`${this.basePath}/picking-summaries/shift`, {
      location,
    });
    if (result && result.data) {
      return result.data as PickingShiftSummariesResponse;
    }
    return null;
  }

  async getPackingSummariesActiveOrders(
    location: number | undefined,
  ): Promise<PackingActiveOrderSummariesResponse | null> {
    const result = await this.get(
      `${this.basePath}/packing-summaries/active-orders`,
      {
        location,
      },
    );
    if (result && result.data) {
      return result.data as PackingActiveOrderSummariesResponse;
    }
    return null;
  }

  async getPackingSummariesShift(
    location: number | undefined,
  ): Promise<PackingShiftSummariesResponse | null> {
    const result = await this.get(`${this.basePath}/packing-summaries/shift`, {
      location,
    });
    if (result && result.data) {
      return result.data as PackingShiftSummariesResponse;
    }
    return null;
  }

  async getFailedOrders(location: number | undefined): Promise<number> {
    const result = await this.get(`${this.basePath}/failed-orders`, {
      location,
    });
    return result.data as number;
  }

  async prioritizeOrderPick(
    poNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/prioritize-pick`, {
        location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async bulkPrioritizeOrderPick(
    poNumbers: string[],
    location?: number,
  ): Promise<BulkPrioritizeResProps> {
    try {
      const result = await this.post(`${this.basePath}/prioritize-pick/bulk`, {
        location,
        po_numbers: poNumbers,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async unPrioritizeOrderPick(
    poNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/unprioritize-pick`, {
        location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async bulkUnPrioritizeOrderPick(
    poNumbers: string[],
    location?: number,
  ): Promise<BulkPrioritizeResProps> {
    try {
      const result = await this.post(
        `${this.basePath}/unprioritize-pick/bulk`,
        {
          location,
          po_numbers: poNumbers,
        },
      );
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async getReplenishListPaginated(
    queries: Record<string, string | number>,
  ): Promise<ReplenishListResponse> {
    const result = await this.get(`${this.basePath}/replenish-list`, queries);
    if (result && result.data) {
      return result.data as ReplenishListResponse;
    }
    return [];
  }

  async getUserListPaginated(
    queries: Record<string, string | number>,
  ): Promise<UserListResponse> {
    try {
      const result = await this.get(`${this.basePath}/users`, queries);
      return result.data.map((user: UserAPIResponse) => ({
        userName: user.name,
        roles: user.roles,
        warehouse: user.location,
        userStatus: user.user_status,
      }));
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async getBinListPaginated(
    queries: Record<string, string | number>,
  ): Promise<BinListResponse> {
    const result = await this.get(`${this.basePath}/bin-list`, queries);
    if (result && result.data) {
      return result.data as BinListResponse;
    }
    return [];
  }

  async getStockTransferListPaginated(
    queries: Record<string, string | number>,
  ): Promise<StockTransferListResponse> {
    const result = await this.get(
      `${this.basePath}/stock-transfer-list`,
      queries,
    );
    if (result && result.data) {
      return result.data as StockTransferListResponse;
    }
    return [];
  }

  async retryUpdate(poNumber: string, location?: number): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/retry`, {
        location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async bulkRetryUpdate(
    poNumbers: string[],
    location?: number,
  ): Promise<IBulkUpdateRetryResProps> {
    try {
      const result = await this.post(`${this.basePath}/retry/bulk`, {
        location,
        po_numbers: poNumbers,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getActiveLocations(): Promise<DashboardActiveLocation[]> {
    try {
      const result = await this.get(`${this.basePath}/active-locations`);
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async prioritizeReplenishTask(
    replenishNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/prioritize-replenish`, {
        location,
        replenish_number: replenishNumber,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async bulkPrioritizeReplenishTask(
    replenishNumbers: string[],
    location?: number,
  ): Promise<BulkPrioritizeResProps> {
    try {
      const result = await this.post(
        `${this.basePath}/prioritize-replenish/bulk`,
        {
          location,
          replenish_numbers: replenishNumbers,
        },
      );
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async unPrioritizeReplenishTask(
    replenishNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(
        `${this.basePath}/unprioritize-replenish`,
        {
          location,
          replenish_number: replenishNumber,
        },
      );
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async bulkUnPrioritizeReplenishTask(
    replenishNumbers: string[],
    location?: number,
  ): Promise<BulkPrioritizeResProps> {
    try {
      const result = await this.post(
        `${this.basePath}/unprioritize-replenish/bulk`,
        {
          location,
          replenish_numbers: replenishNumbers,
        },
      );
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async setCancelNonPickedTask(
    poNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/set-non-picked`, {
        location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async retryUpdateReplenish(
    replenishNumber: string,
    location?: number,
  ): Promise<boolean> {
    try {
      const result = await this.post(`${this.basePath}/retry-replenish`, {
        location,
        replenish_number: replenishNumber,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async bulkRetryUpdateReplenish(
    replenishNumbers: string[],
    location?: number,
  ): Promise<IBulkUpdateRetryResProps> {
    try {
      const result = await this.post(`${this.basePath}/retry-replenish/bulk`, {
        location,
        replenish_numbers: replenishNumbers,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async removeQueue(
    replenishType: ReplenishType,
    location?: number,
  ): Promise<IRemoveReplenishQueueResProps> {
    try {
      const result = await this.post(`${this.basePath}/replenish/remove`, {
        location,
        replenish_type: replenishType,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async retryUpdateStockTransfer(
    transferNumbers: string[],
    location?: number,
  ): Promise<IBulkUpdateRetryResProps> {
    try {
      const result = await this.post(`${this.basePath}/retry-stock-transfer`, {
        location,
        transfer_numbers: transferNumbers,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async bulkUpload(
    file: File,
    fileImportType: FileImportType,
    location?: number,
  ): Promise<IFileImport> {
    try {
      const form = new FormData();
      form.append(
        'file',
        new Blob(
          [await file.arrayBuffer()],

          { type: 'text/csv' },
        ),
        file.name,
      );
      form.append('file_import_type', fileImportType);
      if (location) form.append('location', location.toString());

      let bulkUploadEndpoint = 'bulk-upload';
      if (
        [
          FileImportType.ADD_USER,
          FileImportType.DELETE_USER,
          FileImportType.PHARMACY_COPY_INVOICE_FLAG,
        ].includes(fileImportType)
      ) {
        bulkUploadEndpoint = 'super-admin-bulk-upload';
      }

      const result = await this.post(
        `${this.basePath}/${bulkUploadEndpoint}`,
        form,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getBulkUploadHistoryPaginated(
    queries: Record<string, string | number>,
  ): Promise<IFileImport[]> {
    try {
      const result = await this.get(`${this.basePath}/upload-history`, queries);
      return result.data;
    } catch (error: any) {
      console.warn(error);
      throw error;
    }
  }

  async completePacking(poNumber: string, location?: number): Promise<void> {
    try {
      const result = await this.post(`${this.basePath}/complete-packing`, {
        warehouse_id: location,
        po_number: poNumber,
      });
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async postCreateBin(
    bin: Pick<ProductDetail, 'bin' | 'bin_type' | 'sku_code'>,
    location?: number,
  ): Promise<void> {
    try {
      const payload: IPostCreateBinRequest = {
        ...bin,
        location,
      };
      const result = await this.post(`${this.basePath}/bins`, payload);
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swiperxWmsApiDashboard = new SwipeRxWmsApiDashboard();
