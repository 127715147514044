import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { PRIMARY_GRAY, PRIMARY_RED } from 'commons/styles/colors';

class HeaderTaskPageStyle extends CommonComponentStyle {
  Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: #fff;
    top: 0;
    left: 0;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  `;

  HeaderTitle = styled.span`
    color: #000;
    font-size: 14px;
    font-weight: 500;
  `;

  HeaderLogo = styled.img`
    width: 135px;
  `;

  GridItem = styled(Grid)`
    padding: 12px 8px 8px 8px;
    height: 50px;
  `;

  MenuIcon = styled.img`
    margin-top: 6px;
    height: 45px;
    size: 'small';
    color: 'inherit';
  `;

  MenuText = styled.p`
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-left: 8px;
  `;

  TimeOffOverlay = styled.div`
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `;

  TimerWrapper = styled.div`
    font-size: 14px;
    color: ${PRIMARY_GRAY};
    span {
      margin-left: 8px;
    }

    &.break-time span {
      color: ${PRIMARY_RED};
    }
  `;
}

export const HeaderTaskStyle = new HeaderTaskPageStyle();
