import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  ArrowBackIos,
  AccountCircle,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Schedule,
} from '@mui/icons-material';
import { capitalize, Grid, IconButton, Menu, MenuItem } from '@mui/material';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { logUserAction, updateLogoutHistory } from 'redux-stores/actions';
import { LogUserEventType } from 'redux-stores/enums/logUserEventType.enum';
import {
  logout,
  selectUser,
  setActiveTime,
} from 'redux-stores/features/authSlice';
import { AppDispatch } from 'redux-stores/store';

import { TimeOffDialog } from '../TimeOffDialog';

import { ActiveTimer } from './components/active-timer.component';
import { HeaderTaskStyle as S } from './Header.styles';

interface HeaderProps {
  title?: string;
  prevPageHandler?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, prevPageHandler }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const backToPreviousPage = (): void => {
    if (prevPageHandler) {
      prevPageHandler();
    } else {
      navigate(-1);
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    if (user.stationNumber) dispatch(updateLogoutHistory());
    if (user.activeRole) {
      dispatch(
        logUserAction({
          action: LogUserEventType.LOGOUT,
          activeRole: user.activeRole,
          time: new Date(),
        }),
      );
    }
    dispatch(logout());
    setAnchorEl(null);
  };

  const handleUserRoles = (roles: string[]) => {
    return user.roles
      .filter((role) => role !== 'default-roles-wms')
      .map((role) => capitalize(role.replace('_', ' ')))
      .join(' | ');
  };

  const handleBreakEnd = (): void => {
    if (user.activeRole) {
      const breakEnd = new Date();
      dispatch(
        logUserAction({
          action: LogUserEventType.BREAK_END,
          activeRole: user.activeRole,
          time: breakEnd,
        }),
      );
      const { deduction, start, latestBreakStart } = user.activeTime;
      dispatch(
        setActiveTime({
          isActive: true,
          deduction:
            deduction +
            (latestBreakStart
              ? breakEnd.getTime() - latestBreakStart.getTime()
              : 0),
          start,
          latestBreakStart: null,
        }),
      );
    }
  };

  const [openTimeOffModal, setOpenTimeOffModal] = useState<boolean>(false);

  const onTimeOffModalOpen = () => setOpenTimeOffModal(true);
  const onTimeOffModalClose = () => setOpenTimeOffModal(false);

  return (
    <>
      <S.Wrapper>
        <Grid container style={{ maxHeight: 50 }}>
          <S.GridItem item xs={4} sm={5}>
            {title ? (
              <>
                <IconButton onClick={backToPreviousPage} style={{ padding: 0 }}>
                  <ArrowBackIos style={{ color: '#000' }} />
                </IconButton>
                <S.HeaderTitle>{title}</S.HeaderTitle>
              </>
            ) : (
              <S.HeaderLogo src="/assets/swiperx-logo.svg" />
            )}
          </S.GridItem>
          <S.GridItem item xs={7} sm={5}>
            <ActiveTimer />
          </S.GridItem>
          <S.GridItem item xs={1} sm={2}>
            <div>
              <IconButton
                sx={{ mr: 2 }}
                onClick={handleMenu}
                style={{ padding: 0, float: 'right' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <IconButton>
                    <AccountCircle />
                  </IconButton>
                  <S.MenuText>
                    {user?.userName} {`(${handleUserRoles(user.roles)})`}
                  </S.MenuText>
                </MenuItem>
                {user.userName &&
                  user.activeRole !== UserRolesEnum.SUPER_ADMIN &&
                  (user.activeTime.isActive ? (
                    <MenuItem onClick={onTimeOffModalOpen}>
                      <IconButton>
                        <Schedule />
                      </IconButton>
                      <S.MenuText>Time Off</S.MenuText>
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={handleBreakEnd}>
                      <IconButton>
                        <Schedule />
                      </IconButton>
                      <S.MenuText>Start Timer</S.MenuText>
                    </MenuItem>
                  ))}
                <MenuItem onClick={handleLogout}>
                  <IconButton>
                    <LogoutIcon />
                  </IconButton>
                  <S.MenuText>Logout</S.MenuText>
                </MenuItem>
              </Menu>
            </div>
          </S.GridItem>
        </Grid>
      </S.Wrapper>
      {/* Add fixed space so every page will start below header */}
      <div style={{ marginTop: 55 }} />
      {user.userName && !user.activeTime.isActive && <S.TimeOffOverlay />}
      {openTimeOffModal && (
        <TimeOffDialog
          onClose={onTimeOffModalClose}
          openModal={openTimeOffModal}
        />
      )}
    </>
  );
};
