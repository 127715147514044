import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { CircularProgress, Grid, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import qs from 'query-string';

import { User } from 'commons/types/user.interface';
import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { getRootUrl } from 'commons/utils/getRootUrl';
import { logUserAction } from 'redux-stores/actions';
import { LogUserEventType } from 'redux-stores/enums/logUserEventType.enum';
import { AppDispatch } from 'redux-stores/store';
import { KeycloakToken } from 'services/keycloak/interfaces/KeycloakToken.interface';
import { KeycloakAPI } from 'services/keycloak/Keycloak.class';

import { login } from '../../redux-stores/features/authSlice';

interface DecodedUserToken {
  preferred_username: string;
  realm_access: {
    roles: string[];
  };
  warehouse: string;
  exp: number;
  warehouse_id: string;
}

const getCodeFlow = (): string => {
  const queryString = qs.parse(window.location.search);
  const codeFlow = queryString.code ? queryString.code.toString() : null;
  if (!codeFlow) {
    throw new Error('code token not found');
  }
  return codeFlow;
};

const CodeFlowCallback = (): React.JSX.Element | undefined => {
  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState({});

  const [user, setUser] = useState<User>({
    isAuthenticated: false,
    userName: '',
    roles: [],
    activeRole: null,
    warehouse: '',
    warehouseId: 0,
    activeTime: {
      isActive: false,
      deduction: 0,
      start: null,
      latestBreakStart: null,
    },
  });

  const getToken = async (codeFlow: string) => {
    try {
      const data = await KeycloakAPI.getToken(codeFlow);
      console.log(data);
      const decodedToken = data.access_token
        ? (jwtDecode(data.access_token) as DecodedUserToken)
        : null;

      if (!decodedToken) {
        throw new Error('tokenDecode null');
      }

      const roles = decodedToken?.realm_access.roles;
      const {
        preferred_username: preferredUsername,
        warehouse,
        warehouse_id: warehouseId,
      } = decodedToken;

      const _user: User = {
        isAuthenticated: true,
        userName: preferredUsername,
        roles,
        activeRole: roles.length === 1 ? (roles[0] as UserRolesEnum) : null,
        warehouse,
        warehouseId: parseInt(warehouseId, 10),
        activeTime: {
          isActive: true,
          deduction: 0,
          start: new Date(),
          latestBreakStart: null,
        },
      };

      const userToken: KeycloakToken = {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        id_token: data.id_token,
        exp: decodedToken?.exp,
      };
      if (_user) {
        setUser(_user);
        dispatch(login(_user));
        setStatus('done');

        if (_user.activeRole) {
          dispatch(
            logUserAction({
              action: LogUserEventType.LOGIN,
              activeRole: _user.activeRole,
              time: new Date(),
            }),
          );
        }
      }

      if (userToken) {
        localStorage.setItem('userToken', JSON.stringify(userToken));
      }
    } catch (e) {
      setStatus('error');
      setError(e as Error);
    }
  };

  useEffect(() => {
    const codeFlow = getCodeFlow();

    getToken(codeFlow).catch(console.error);
  }, []);

  if (status === 'processing') {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        component="div"
      >
        <CircularProgress />
        <Typography style={{ marginLeft: '10px' }}>Please Wait</Typography>
      </Grid>
    );
  } else if (status === 'done' && user?.isAuthenticated) {
    // TODO log login?
    const url = getRootUrl(user.activeRole);
    return <Navigate to={url} />;
  } else if (status === 'error') {
    console.error(error);
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        component="div"
      >
        <Typography style={{ fontWeight: 600 }}>
          Error: {`${error.toString()}`}
        </Typography>
        <Typography>
          You will be redirected to <a href="/login">Login page</a>
        </Typography>
      </Grid>
    );
  }
  return undefined;
};

export default CodeFlowCallback;
