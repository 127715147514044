import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Header } from 'commons/components/Header';
import { getRootUrl } from 'commons/utils/getRootUrl';
import { selectUser } from 'redux-stores/features/authSlice';
import { Keycloak } from 'services/keycloak/Keycloak.class';

import S from './Login.styles';

const LoginPage = (): React.JSX.Element => {
  const user = useSelector(selectUser);

  const handleLogin = React.useCallback(
    // eslint-disable-next-line no-return-assign
    () => (window.location.href = Keycloak.getLoginUrl()),
    [],
  );

  if (!user?.isAuthenticated) {
    return (
      <div>
        <Header />
        <S.Text>
          Please &nbsp;
          <S.Btn type="button" onClick={handleLogin}>
            Login
          </S.Btn>
          &nbsp;to continue
        </S.Text>
      </div>
    );
  } else {
    const url = getRootUrl(user.activeRole);
    return <Navigate to={url} />;
  }
};

export default LoginPage;
