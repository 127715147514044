import { createAsyncThunk } from '@reduxjs/toolkit';

import { PRIMARY_BLUE } from 'commons/styles/colors';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { LogUserEventType } from 'redux-stores/enums/logUserEventType.enum';
import { setActiveTime } from 'redux-stores/features/authSlice';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swipeRxWmsApiAlertNotification } from 'services/apis/SwipeRxWmsApiAlertNotification';
import { swipeRxWmsApiUser } from 'services/apis/SwipeRxWmsApiUser';

export const getUserAlertNotification = createAsyncThunk(
  'utility/getUserAlertNotification',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiAlertNotification.getAlertNotification();
      if (data) {
        dispatch(
          snackbarSetData({
            open: true,
            message: data.message,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
    return undefined;
  },
);

export const logUserAction = createAsyncThunk(
  'utility/LogUserAction',
  async (
    params: {
      action: LogUserEventType;
      activeRole: string;
      time?: Date;
      description?: Record<string, any>;
    },
    { dispatch },
  ) => {
    const { activeRole, action, time = new Date(), description } = params;
    defaultActionProcess(async () => {
      await swipeRxWmsApiUser.logAction({
        activeRole,
        action,
        time,
        description,
      });
    }, dispatch);
    return undefined;
  },
);

export const getActiveTime = createAsyncThunk(
  'utility/getActiveTime',
  async (_, { dispatch }) => {
    try {
      const {
        deduction_time_in_ms: deduction,
        is_timer_active: isActive,
        on_going_login_time: start,
        latest_break_start_time: latestBreakStart,
      } = await swipeRxWmsApiUser.getActiveTime();
      dispatch(
        setActiveTime({
          isActive,
          deduction,
          start: start ? new Date(start) : null,
          latestBreakStart: latestBreakStart
            ? new Date(latestBreakStart)
            : null,
        }),
      );
    } catch (e) {
      // ignore error
    }
  },
);
