import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  capitalize,
} from '@mui/material';

import { TimeOffReason } from 'commons/enums/time-off-reason.enum';
import { logUserAction } from 'redux-stores/actions';
import { LogUserEventType } from 'redux-stores/enums/logUserEventType.enum';
import { selectUser, setActiveTime } from 'redux-stores/features/authSlice';
import { AppDispatch } from 'redux-stores/store';

import { TimeOffDialogStyle as S } from './TimeOffDialog.styles';

export interface ITimeOffDialogProps {
  onClose: () => void;
  openModal: boolean;
}

export const TimeOffDialog: React.FC<ITimeOffDialogProps> = ({
  onClose,
  openModal,
}) => {
  const [reason, setReason] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);

  const onConfirm = async (): Promise<void> => {
    if (user.activeRole) {
      const breakStart = new Date();
      dispatch(
        logUserAction({
          action: LogUserEventType.BREAK_START,
          activeRole: user.activeRole,
          time: breakStart,
          description: { reason },
        }),
      );
      const { deduction, start } = user.activeTime;
      dispatch(
        setActiveTime({
          isActive: false,
          deduction,
          start,
          latestBreakStart: breakStart,
        }),
      );
    }
  };

  const onFieldChange = (value: string | null): void => {
    if (value) setReason(value);
  };

  return (
    <Dialog
      onClose={onClose}
      open={openModal}
      style={{ whiteSpace: 'pre-line' }}
    >
      <S.TitleWrapper>
        <S.Title>Time Off Reason</S.Title>
      </S.TitleWrapper>
      <Grid container>
        {/* Test UX for period of time. Old code preserved in case
            need to roll back to previous UI */}
        {/* <S.FieldRowWrapper style={{ textAlign: 'center' }}>
          <Select
            value={reason || ''}
            onChange={(event): void => onFieldChange(event.target.value)}
            fullWidth
          >
            {Object.values(TimeOffReason).map((_reason) => (
              <MenuItem value={_reason}>
                {capitalize(_reason.replace('_', ' '))}
              </MenuItem>
            ))}
          </Select>
        </S.FieldRowWrapper> */}
        <S.FieldRowWrapper item xs={12}>
          <RadioGroup
            name="radio-group-break-reason"
            onChange={(event): void => onFieldChange(event.target.value)}
            style={{ margin: '0px 22px', fontSize: 14 }}
          >
            {Object.values(TimeOffReason).map((_reason) => (
              <FormControlLabel
                value={_reason}
                control={<Radio />}
                label={capitalize(_reason.replace('_', ' '))}
              />
            ))}
          </RadioGroup>
        </S.FieldRowWrapper>
      </Grid>
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>Cancel</S.CancelButton>
        <S.OKButton
          disabled={!reason}
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Submit
        </S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};
