import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close } from '@mui/icons-material';
import { Drawer, Grid, IconButton } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import { PRIMARY_GREEN, PRIMARY_RED } from 'commons/styles/colors';
import { RowTitleProps } from 'commons/types/listPageData.interface';
import { BasketProductDetails } from 'commons/types/packingTask.interface';
import { updateQtyPicked } from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as S } from '../CheckBasket.styles';

import EditQtyTable from './EditQtyTable.component';
import SelectMenu from './SelectMenu.component';

interface IEditQtyModalProps {
  openModal: boolean;
  onClose: () => void;
  data: BasketProductDetails;
  basketNumber: number;
}

const rowTitles: RowTitleProps[] = [
  { title: '' },
  { title: 'Name' },
  { title: 'UoM' },
  { title: 'BIN Number' },
  { title: 'Batch Number' },
  { title: 'QTY Picked' },
];

const reasonList: string[] = [
  'Kurang Barang',
  'Lebih Barang',
  'Salah Barang',
  'Salah Batch',
  'Stock Out',
];

const confirmationDialogData = {
  title: 'Close Confirmation Dialog',
  content: `Are you sure you want to close? (The data haven't been saved yet)`,
};

const EditQtyModal: React.FC<IEditQtyModalProps> = ({
  openModal,
  onClose,
  data,
  basketNumber,
}) => {
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );

  const dispatch = useDispatch<AppDispatch>();
  const [basketDetail, setBasketDetail] = useState<BasketProductDetails>(data);
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [isAvailableSaveButton, setIsAvailableSaveButton] =
    useState<boolean>(false);
  const [totalQtyPicked, setTotalQtyPicked] = useState<number>(0);

  const handleConfirmDialog = (): void => {
    onClose();
    setOpenConfirmation(false);
  };

  const handleCloseDialog = (): void => {
    setOpenConfirmation(false);
  };

  const handleOpenDialog = (): void => {
    setOpenConfirmation(true);
  };

  const handleEditReason = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setBasketDetail({
      ...basketDetail,
      reason: e.currentTarget.value,
    });
    setIsOpenSelect(false);
  };

  const handleClickSelect = (): void => {
    setIsOpenSelect(!isOpenSelect);
  };

  const debounceCheckValidPickActual = useDebouncedCallback(
    async () => {
      const isReasonValid = basketDetail.reason !== null;
      // make sure all bin and inventory number not empty
      const isPickActualValidResult = basketDetail.pick_actual.every(
        (item) => item.bin !== '' && item.inventory_number !== '',
      );
      const isTotalQtyPickedPass =
        totalQtyPicked <= basketDetail.quantity_ordered;
      setIsAvailableSaveButton(
        isPickActualValidResult && isReasonValid && isTotalQtyPickedPass,
      );
    },
    500,
    { maxWait: 2000, trailing: true },
  );

  const updateBasketDetail = (detail: Partial<BasketProductDetails>): void => {
    setBasketDetail({
      ...basketDetail,
      ...detail,
    });
  };

  const handleSave = (): void => {
    let editReason = basketDetail.reason;
    const currTotalQtyPicked = data.metadata
      ? data.metadata.qtyBeforeEdit
      : data.total_qty_picked;
    const newTotalQtyPicked = basketDetail.total_qty_picked;
    const isQtyChanged = currTotalQtyPicked !== newTotalQtyPicked;
    if (
      basketDetail.reason &&
      basketDetail.reason !== 'Salah Batch' &&
      isQtyChanged
    ) {
      editReason = `${basketDetail.reason} [ubah quantity total: ${currTotalQtyPicked} -> ${newTotalQtyPicked}]`;
    }
    dispatch(
      updateQtyPicked({
        poNumber: packingTask?.po_number ?? '',
        payload: {
          ...basketDetail,
          basket: basketNumber,
          reason: editReason,
          check_passed:
            totalQtyPicked !== basketDetail.quantity_ordered
              ? false
              : basketDetail.check_passed,
        },
        onClose,
      }),
    );
  };

  // count the total qty picked here
  useMemo(() => {
    const qtyTotal = packingTask?.basket_infos
      .flatMap((info) =>
        info.items.flatMap((item) => {
          if (item.sku_code === basketDetail.sku_code) {
            if (info.basket === basketNumber) {
              return basketDetail.total_qty_picked;
            }
            return item.total_qty_picked;
          }
          return 0;
        }),
      )
      .reduce((sum, data) => sum + data, 0);
    setTotalQtyPicked(qtyTotal ?? 0);
  }, [basketDetail]);

  useEffect(() => {
    debounceCheckValidPickActual();
  }, [basketDetail, debounceCheckValidPickActual]);

  return (
    <>
      <Drawer anchor="bottom" open={openModal}>
        <S.DrawerHeader container>
          <Grid
            container
            xs={11}
            style={{ paddingLeft: '8px', alignItems: 'center' }}
          >
            <S.BoldText>EDIT QTY</S.BoldText>
          </Grid>
          <Grid container xs={1} alignItems="center" justifyContent="flex-end">
            <IconButton
              onClick={() => handleOpenDialog()}
              data-testid="closePopupEdit"
            >
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </S.DrawerHeader>
        <S.SKUSection container>
          <Grid container xs={7}>
            <div>{'SKU: '}</div>
            <S.BoldText data-testid="popupSKU">{data.sku_code}</S.BoldText>
          </Grid>
          <Grid container xs={5} gap="16px" justifyContent="flex-end">
            <S.Flex>
              <div>{'ORDER QTY: '}</div>
              <S.BoldText data-testid="popupOrderQTY">
                {data.quantity_ordered}
              </S.BoldText>
            </S.Flex>
            <S.VerticalLine />
            <S.Flex>
              <div>{'QTY PICKED: '}</div>
              <S.BoldText
                style={{
                  color:
                    data.quantity_ordered === totalQtyPicked
                      ? PRIMARY_GREEN
                      : PRIMARY_RED,
                }}
                data-testid="popupOrderPicked"
              >{`${totalQtyPicked}/${data.quantity_ordered}`}</S.BoldText>
            </S.Flex>
          </Grid>
        </S.SKUSection>
        <EditQtyTable
          rowTitles={rowTitles}
          data={basketDetail.pick_actual}
          uom={basketDetail.uom}
          basket={basketNumber}
          name={basketDetail.name}
          skuCode={basketDetail.sku_code}
          updateBasketDetail={updateBasketDetail}
          quantityOrdered={basketDetail.quantity_ordered}
          totalQtyPicked={totalQtyPicked}
        />
        <S.HorizontalLine style={{ margin: '16px' }} />
        <S.Wrapper>
          <div style={{ fontWeight: 700 }}>
            Please provide a reason for the changes made
          </div>
          {/* If this reason is not fixed, create arr and map the item */}
          <SelectMenu
            placeholder="Select a reason..."
            onClick={handleClickSelect}
            isOpen={isOpenSelect}
            value={basketDetail.reason ?? ''}
            dataTestId="dropdownReason"
          >
            <S.SelectMenu>
              {reasonList.map((reason) => (
                <S.SelectOption onClick={handleEditReason} value={reason}>
                  {reason}
                </S.SelectOption>
              ))}
            </S.SelectMenu>
          </SelectMenu>
        </S.Wrapper>
        <S.ModalFooterWrapper>
          <S.PrimaryButton
            disabled={!isAvailableSaveButton}
            onClick={handleSave}
            data-testid="saveEdit"
          >
            SAVE
          </S.PrimaryButton>
        </S.ModalFooterWrapper>
      </Drawer>
      <ConfirmationDialog
        title={confirmationDialogData.title}
        content={confirmationDialogData.content}
        onClose={() => handleCloseDialog()}
        openModal={openConfirmation}
        onConfirm={() => handleConfirmDialog()}
        dataTestIdOnClose="BtnCheckEditNo"
        dataTestIdOnConfirm="BtnCheckEditYes"
      />
    </>
  );
};
export default EditQtyModal;
