import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { OrderListRowAction, PrintPDFTypeEnum } from 'commons/enums';

export interface IRowActionBtnProps {
  open: boolean;
  options: {
    label: string;
    key: PrintPDFTypeEnum | OrderListRowAction;
  }[];
  handleToggle: () => void;
  handleMenuClick: (menuKey: PrintPDFTypeEnum | OrderListRowAction) => void;
  handleClose: (event: Event) => void;
}

const RowActionButton: React.FC<IRowActionBtnProps> = ({
  open,
  options,
  handleToggle,
  handleClose,
  handleMenuClick,
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        aria-controls={open ? 'row-action-btn-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select actions"
        aria-haspopup="menu"
        onClick={handleToggle}
        variant="outlined"
        ref={anchorRef}
        style={{ marginLeft: '4px' }}
      >
        Actions
        <ArrowDropDownIcon />
      </Button>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="action-btn-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.key}
                      onClick={() => handleMenuClick(option.key)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
export default RowActionButton;
