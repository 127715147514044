import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User, UserState } from 'commons/types/user.interface';
import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { Keycloak } from 'services/keycloak/Keycloak.class';

const initialState: UserState = {
  user: {
    isAuthenticated: false,
    userName: '',
    roles: [],
    activeRole: null,
    warehouse: '',
    warehouseId: 0,
    activeTime: {
      isActive: false,
      deduction: 0,
      start: null,
      latestBreakStart: null,
    },
  }, // for user object
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logout: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.user = initialState.user;
      window.location.href = Keycloak.getLogoutUrl();
    },
    login: (state, action: PayloadAction<User>) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
      console.log(`login: ${JSON.stringify(state.user)}`);
    },
    setActiveRole: (state, action: PayloadAction<UserRolesEnum>) => {
      // eslint-disable-next-line no-param-reassign
      state.user.activeRole = action.payload || null;
      // eslint-disable-next-line no-param-reassign
      state.user.activeTime = {
        isActive: true,
        deduction: 0,
        start: new Date(),
        latestBreakStart: null,
      };
    },
    setActiveTime: (
      state,
      action: PayloadAction<{
        isActive: boolean;
        deduction: number;
        start: Date | null;
        latestBreakStart: Date | null;
      }>,
    ) => {
      const { start, latestBreakStart, ...restParams } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.user.activeTime = {
        start: start ? new Date(start) : null,
        latestBreakStart: latestBreakStart ? new Date(latestBreakStart) : null,

        ...restParams,
        timer: state.user.activeTime.timer,
      };
    },
    setTimer: (
      state,
      action: PayloadAction<{
        hours: number;
        minutes: number;
        seconds: number;
      }>,
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.user.activeTime.timer = action.payload;
    },
    relogin: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.user = initialState.user;
      // TODO clear local storage
      localStorage.clear();
      window.location.href = '/login';
    },
  },
});

export const {
  login,
  logout,
  relogin,
  setActiveRole,
  setActiveTime,
  setTimer,
} = authSlice.actions;

// export default authSlice.reducer

export const authReducer = authSlice.reducer;

export const selectUser = (state: any): User => state.auth.user;
