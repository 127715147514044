/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Dialog } from '@mui/material';

import { IConfirmationDialogProps } from 'commons/types/confirmationDialog.interface';

import * as S from './ConfirmationDialog.styles';

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  title,
  content,
  onClose,
  openModal,
  onConfirm,
  callOnCloseOnHandleConfirm = true,
  dataTestIdOnClose,
  dataTestIdOnConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm();
    if (callOnCloseOnHandleConfirm) onClose();
  };

  // function to breakdown if there's any special tag like <b> </b> inside text and transform it
  // to actual html tag. Idea is to avoid dangerouslySetInnerHTML usage.
  const formattedText = content.split(/(<b>.*?<\/b>)/g).map((part) => {
    if (part.startsWith('<b>') && part.endsWith('</b>')) {
      // Strip <b> and </b> and wrap the content in a <b> element
      const boldText = part.slice(3, -4); // Remove the <b> and </b> tags
      return <b>{boldText}</b>;
    }
    return part; // Return normal text
  });

  return (
    <Dialog
      onClose={onClose}
      open={openModal}
      style={{ whiteSpace: 'pre-line' }}
    >
      <S.TitleWrapper>
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>

      <S.Text>{formattedText}</S.Text>

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton data-testid={dataTestIdOnClose} onClick={onClose}>
          NO
        </S.CancelButton>
        <S.OKButton data-testid={dataTestIdOnConfirm} onClick={handleConfirm}>
          YES
        </S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};

export default ConfirmationDialog;
