import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close } from '@mui/icons-material';
import {
  Button,
  capitalize,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import * as S from 'commons/components/Table/Table.styles';
import { StockTransferSearchCriteriaEnum } from 'commons/enums';
import { IStockTransfer } from 'commons/types';
import {
  DefaultSortProps,
  RowTitleProps,
  SortOrder,
} from 'commons/types/listPageData.interface';
import { StockTransferDataTableProps } from 'commons/types/stockTransferItemData.interface';
import { TableActionRow, DataTable } from 'pages/dashboard/commons/components';
import {
  IOpenBulkDialogData,
  IOpenDialogData,
} from 'pages/dashboard/order-lists/type/open-dialog-state.interface';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import {
  OpenBulkDialogConfig,
  OpenDialogConfig,
} from '../configs/open-dialog.config';

import { ActionBtnMenuStockTransfer } from './ActionBtnMenuStockTransfer.component';

const rowTitles: RowTitleProps[] = [
  { title: 'SKU Code' },
  { title: 'Start Date', sortField: 'start_transfer_at' },
  { title: 'Status' },
  { title: 'User' },
];

const defaultSort: DefaultSortProps = {
  sortField: 'start_transfer_at',
  sortOrder: SortOrder.DESC,
};

const Row: React.FC<{
  data: IStockTransfer;
  onCheckboxClick: (skuCode: string) => void;
  checked: boolean;
  selectedLocation?: number;
  statusFilter?: StockTransferSearchCriteriaEnum | null;
}> = ({ data, onCheckboxClick, checked, selectedLocation, statusFilter }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState<boolean>(false);
  const [openDialogData, setOpenDialogData] = useState<IOpenDialogData | null>(
    null,
  );

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const handleDetails = (): void => {
    setDetailsOpen(!detailsOpen);
  };

  const handleOpenDialog = (name: string): void => {
    setOpenDialogData(OpenDialogConfig[name.toLocaleLowerCase()]);
    setOpen(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialogData(null);
    setOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleDetails}
        open={detailsOpen}
      >
        <DialogTitle>Details</DialogTitle>
        <IconButton
          onClick={handleDetails}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <b>Stock Transfer Number:</b> {data.transfer_number}
            <br />
            <b>Stock Transfer Date:</b>{' '}
            {new Date(data.start_transfer_at || '').toLocaleDateString('id-ID')}
            <br />
            <b>Stock Transfer Finished:</b>{' '}
            {new Date(data.finished_transfer_at || '').toLocaleDateString(
              'id-ID',
            )}
            <br />
            <b>Status:</b> {data.status}
            <br />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <S.TableRow>
        <S.TableColumn>
          <Checkbox
            onClick={() => onCheckboxClick(data.transfer_number)}
            checked={checked}
          />
        </S.TableColumn>
        <S.TableColumn />
        <S.TableColumn>{data.sku_code}</S.TableColumn>
        <S.TableColumn>
          {data.start_transfer_at
            ? new Date(data.start_transfer_at).toLocaleString('id-ID')
            : null}
        </S.TableColumn>
        <S.TableColumn style={{ textTransform: 'capitalize' }}>
          {capitalize(data.status.replace('-', ' '))}
        </S.TableColumn>
        <S.TableColumn>{data.user}</S.TableColumn>
        <S.TableColumn style={{ textAlign: 'right' }}>
          {statusFilter === StockTransferSearchCriteriaEnum.UPDATE_FAILED ||
          statusFilter === StockTransferSearchCriteriaEnum.TRANSFERRED ? (
            <Button
              onClick={handleDetails}
              variant="outlined"
              sx={{
                width: 87,
              }}
            >
              Detail
            </Button>
          ) : null}
        </S.TableColumn>
      </S.TableRow>
      <ConfirmationDialog
        title={openDialogData?.title || ''}
        content={openDialogData?.content(data.transfer_number) || ''}
        onClose={() => handleCloseDialog()}
        openModal={open}
        onConfirm={() =>
          openDialogData?.onConfirm(
            dispatch,
            data.transfer_number,
            selectedLocation,
          )
        }
      />
    </>
  );
};

const StockTransferTable: React.FC<StockTransferDataTableProps> = (
  params,
): React.ReactNode => {
  const { data = [], statusFilter }: StockTransferDataTableProps = params;
  const dispatch = useDispatch<AppDispatch>();

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedTasks, setCheckedTasks] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openDialogData, setOpenDialogData] =
    useState<IOpenBulkDialogData | null>(null);

  const selectedTasks = data.filter((task) =>
    checkedTasks.includes(task.transfer_number),
  );

  const { selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const selectedStockTransferNumbers = selectedTasks.map(
    (task) => task.transfer_number,
  );

  const handleCheckTask = (stockTransferNumber: string): void => {
    if (checkedTasks.includes(stockTransferNumber)) {
      // remove skuCode from checkedTasks
      checkedTasks.splice(checkedTasks.indexOf(stockTransferNumber), 1);
      setCheckedTasks([...checkedTasks]);
    } else {
      // insert stock transfer number to checkedOrders
      setCheckedTasks([...checkedTasks, stockTransferNumber]);
    }
  };

  const handleCheckAll = (): void => {
    if (checkAll) {
      // remove all check list
      setCheckedTasks([]);
    } else {
      // add all sku code to checkedTasks
      setCheckedTasks(data.map((task) => task.transfer_number));
    }
    setCheckAll(!checkAll);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (name: string): void => {
    setOpenDialogData(OpenBulkDialogConfig[name]);
    setOpen(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialogData(null);
    setOpen(false);
  };

  return (
    <>
      <TableActionRow
        dataName="stock-transfer"
        selectedData={selectedTasks}
        anchorEl={anchorEl}
        openMenu={openMenu}
        handleClickMenu={handleClickMenu}
      >
        <ActionBtnMenuStockTransfer
          selectedTask={selectedTasks}
          handleCloseMenu={handleCloseMenu}
          handleOpen={handleOpenDialog}
        />
      </TableActionRow>
      <DataTable
        handleCheckAll={handleCheckAll}
        checkAll={checkAll}
        rowTitles={rowTitles}
        defaultSort={defaultSort}
      >
        <S.TableBody>
          {data.map((item) => (
            <Row
              data={item}
              key={item.transfer_number}
              onCheckboxClick={handleCheckTask}
              checked={checkedTasks.includes(item.transfer_number)}
              selectedLocation={selectedLocation?.warehouse_id}
              statusFilter={statusFilter}
            />
          ))}
        </S.TableBody>
      </DataTable>
      <ConfirmationDialog
        title={openDialogData?.title || ''}
        content={openDialogData?.content(selectedStockTransferNumbers) || ''}
        onClose={() => handleCloseDialog()}
        openModal={open}
        onConfirm={() =>
          openDialogData?.onConfirm(
            dispatch,
            selectedStockTransferNumbers,
            selectedLocation?.warehouse_id,
          )
        }
      />
    </>
  );
};

export default StockTransferTable;
