import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import RowActionButton from 'commons/components/RowActionButton/RowActionButton.component';
import { OrderListRowAction, PrintPDFTypeEnum } from 'commons/enums';
import { PickingTask } from 'commons/types';
import { AppDispatch } from 'redux-stores/store';

import { OpenDialogConfig } from '../configs/open-dialog.config';
import { IOpenDialogData } from '../type/open-dialog-state.interface';

const PickingRowActionBtn: React.FC<{
  pickingTask: PickingTask;
  selectedLocation?: number;
}> = ({ pickingTask, selectedLocation }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openDialogData, setOpenDialogData] = useState<IOpenDialogData | null>(
    null,
  );

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const options: {
    label: string;
    key: PrintPDFTypeEnum | OrderListRowAction;
  }[] = [{ label: 'No Pick Cancel', key: OrderListRowAction.NO_PICK_CANCEL }];

  if (pickingTask.is_high_priority) {
    options.push({
      label: 'Unprioritize',
      key: OrderListRowAction.UNPRIORITIZE_TASK,
    });
  } else {
    options.push({
      label: 'Prioritize',
      key: OrderListRowAction.PRIORITIZE_TASK,
    });
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleOpenDialog = (name: string) => {
    setOpenDialogData(OpenDialogConfig[name.toLocaleLowerCase()]);
    setOpenConfirmation(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogData(null);
    setOpenConfirmation(false);
  };

  const handleMenuClick = (menuKey: PrintPDFTypeEnum | OrderListRowAction) => {
    switch (menuKey) {
      case OrderListRowAction.UNPRIORITIZE_TASK:
        handleOpenDialog('unprioritize');
        break;
      case OrderListRowAction.PRIORITIZE_TASK:
        handleOpenDialog('prioritize');
        break;
      case OrderListRowAction.NO_PICK_CANCEL:
        handleOpenDialog('no-pick-cancel');
        break;
      default:
    }
  };

  return (
    <>
      <RowActionButton
        open={open}
        options={options}
        handleClose={handleClose}
        handleMenuClick={handleMenuClick}
        handleToggle={handleToggle}
      />
      <ConfirmationDialog
        title={openDialogData?.title || ''}
        content={openDialogData?.content(pickingTask.po_number) || ''}
        onClose={() => handleCloseDialog()}
        openModal={openConfirmation}
        onConfirm={() =>
          openDialogData?.onConfirm(
            dispatch,
            pickingTask.po_number,
            selectedLocation,
          )
        }
      />
    </>
  );
};
export default PickingRowActionBtn;
