import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConditionalRender } from 'commons/components/ConditionalRender';
import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { getActiveTime } from 'redux-stores/actions';
import { selectUser, setTimer } from 'redux-stores/features/authSlice';
import { AppDispatch } from 'redux-stores/store';

import { HeaderTaskStyle as S } from '../Header.styles';

export const ActiveTimer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const { start, deduction, isActive, latestBreakStart, timer } =
    user.activeTime || {
      start: null,
      deduction: 0,
      isActive: false,
      latestBreakStart: null,
      timer: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };

  const [counterUpdateGetActiveTime, setCounterUpdateGetActiveTime] =
    useState(0);

  useEffect(() => {
    if (user.isAuthenticated && !!user.activeRole && !user.activeTime.start) {
      dispatch(getActiveTime());
    }
  }, [dispatch, user.activeRole, user.activeTime.start, user.isAuthenticated]);

  const ACTIVE_TIME_INTERVAL = 30; // seconds

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        user.isAuthenticated &&
        user.activeRole !== UserRolesEnum.SUPER_ADMIN &&
        !!start
      ) {
        if (user.activeTime.isActive) {
          const nowTime = new Date().getTime();
          const startTime = new Date(start).getTime();
          const activeBreakTime =
            !isActive && !!latestBreakStart
              ? new Date(latestBreakStart).getTime()
              : 0;
          const diff = nowTime - startTime - deduction - activeBreakTime;
          const __hours = Math.floor(diff / (1000 * 60 * 60));
          const __minutes = Math.floor((diff / (1000 * 60)) % 60);
          const __seconds = Math.floor((diff / 1000) % 60);

          dispatch(
            setTimer({
              hours: __hours,
              minutes: __minutes,
              seconds: __seconds,
            }),
          );
        }
        if (counterUpdateGetActiveTime > ACTIVE_TIME_INTERVAL) {
          /* resync timer every x seconds */
          setCounterUpdateGetActiveTime(0);
          dispatch(getActiveTime());
        } else {
          setCounterUpdateGetActiveTime(counterUpdateGetActiveTime + 1);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <ConditionalRender
      condition={
        !!user.activeRole && user.activeRole !== UserRolesEnum.SUPER_ADMIN
      }
    >
      <S.TimerWrapper
        className={user.activeTime.isActive ? 'active-time' : 'break-time'}
      >
        Active Time:
        <span>
          {(timer?.hours || 0).toString().padStart(2, '0')}:
          {(timer?.minutes || 0).toString().padStart(2, '0')}:
          {(timer?.seconds || 0).toString().padStart(2, '0')}
        </span>
      </S.TimerWrapper>
    </ConditionalRender>
  );
};
