import React from 'react';
import { Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import HomePage from 'pages/homepage/HomePage';
import HomePageInbound from 'pages/homepage/HomePageInbound';
import HomePageOutbound from 'pages/homepage/HomePageOutbound';

export const useHomeRouter = (): React.JSX.Element => {
  return (
    <>
      <Route
        path="/home"
        element={
          <PrivateRoute
            roles={[UserRolesEnum.ADMIN, UserRolesEnum.SUPER_ADMIN]}
            isHomePage
          />
        }
      >
        <Route path="/home" element={<HomePage />} />
        <Route path="/home/outbound" element={<HomePageOutbound />} />
      </Route>
      <Route
        path="/home"
        element={
          <PrivateRoute
            roles={[
              UserRolesEnum.ADMIN,
              UserRolesEnum.SUPER_ADMIN,
              UserRolesEnum.REPLENISH,
            ]}
          />
        }
      >
        <Route path="/home/inbound" element={<HomePageInbound />} />
      </Route>
    </>
  );
};
