import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { LIGHTER_GRAY } from 'commons/styles/colors';

class TimeOffDialogStyles extends CommonComponentStyle {
  TitleWrapper = styled.div`
    padding-top: 8px;
    background: ${LIGHTER_GRAY};
    margin-bottom: 16px;
  `;

  Title = styled.p`
    font-weight: 700;
    font-size: 16px;
    margin: 8px 16px;
  `;

  Text = styled.p`
    font-size: 14px;
    margin: 8px 22px;
  `;

  FieldRowWrapper = styled(Grid)`
    width: 300px;
  `;

  FooterWrapper = styled.div`
    text-align: center;
    padding: 16px;
  `;

  OKButton = styled(this.PrimaryButton)`
    margin: 0 8px !important;
    width: 40% !important;
  `;

  CancelButton = styled(this.SecondaryButton)`
    margin: 0 8px !important;
    width: 40% !important;
  `;
}

export const TimeOffDialogStyle = new TimeOffDialogStyles();
