import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useDebouncedCallback } from 'use-debounce';

import { CriteriaOption } from 'commons/components/SearchRelated';
import { getBinListAction } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';

import { ListFilterCard, ListPage } from '../commons/components';

import BinTable from './components/BinTable.component';
import { CreateBinDialog } from './components/CreateBinDialog.component';

const MasterBinPage: React.FC = () => {
  const { binList, selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const updateBinListSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getBinListAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  const searchCriteria: CriteriaOption[] = [
    {
      name: 'Bin Number',
      id: 'bin',
      isArray: true,
      parameterMapping: 'bin_numbers',
    },
    {
      name: 'SKU Code',
      id: 'sku_code',
      isArray: true,
      parameterMapping: 'sku_codes',
    },
  ];

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const onCreateModalOpen = () => setOpenCreateModal(true);
  const onCreateModalClose = () => setOpenCreateModal(false);

  return (
    <ListPage updateListSearchDebounce={updateBinListSearchDebounce}>
      <ListFilterCard searchCriteria={searchCriteria} />
      <BinTable data={binList} onClickCreate={onCreateModalOpen} />
      {openCreateModal && (
        <CreateBinDialog
          onClose={onCreateModalClose}
          openModal={openCreateModal}
          location={selectedLocation?.warehouse_id}
        />
      )}
    </ListPage>
  );
};

export default MasterBinPage;
