import React from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import * as colors from 'commons/styles/colors';

interface SelectMenuProps {
  placeholder?: string;
  onClick?: () => void;
  isOpen?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  value?: string;
  dataTestId?: string;
}

const SelectMenu: React.FC<SelectMenuProps> = ({
  placeholder,
  onClick,
  isOpen,
  children,
  value,
  dataTestId,
}) => {
  return (
    <>
      <TextField
        fullWidth
        type="select"
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton data-testid={dataTestId}>
                <KeyboardArrowDown style={{ color: colors.PRIMARY_GRAY }} />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            style: { cursor: 'pointer' },
          },
          sx: {
            paddingRight: 0,
            cursor: 'pointer',
          },
        }}
      />
      {isOpen ? children : null}
    </>
  );
};

export default SelectMenu;
