import { LogUserEventType } from 'redux-stores/enums/logUserEventType.enum';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

/* eslint-disable class-methods-use-this */
class SwipeRxWmsApiUser extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.USER);
  }

  async assignLocation(locationId: string) {
    // implementation here
    console.log('assignLocation', locationId);
  }

  async getRole() {
    console.log('getRole');
  }

  async logAction(params: {
    action: LogUserEventType;
    activeRole: string;
    time?: Date;
    description?: Record<string, any>;
  }): Promise<void> {
    const { action, activeRole, time = new Date(), description } = params;
    const path = '/time-log/log-action';
    await this.post(path, {
      event_type: action,
      selected_role: activeRole,
      event_time: time,
      description,
    });
  }

  async getActiveTime(): Promise<{
    on_going_login_time: Date | null;
    is_timer_active: boolean;
    deduction_time_in_ms: number; // in ms
    latest_break_start_time: Date | null;
  }> {
    const path = '/time-log/active-time';
    const result = await this.get(path);
    return result.data;
  }
}

export const swipeRxWmsApiUser = new SwipeRxWmsApiUser();
