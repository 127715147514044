import React, { useEffect, useState } from 'react';

import { Brightness1, CameraAlt, ExpandMore } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from '@mui/material';

import { PRIMARY_GRAY, PROGRESS_ICON_COLOR } from 'commons/styles/colors';
import { RowTitleProps } from 'commons/types/listPageData.interface';
import { BasketInfo } from 'commons/types/packingTask.interface';

import { CheckBasketStyle as S } from '../CheckBasket.styles';

import { BasketInfoTable } from './BasketInfoTable.component';
import UploadPhotoModal from './UploadPhotoModal.component';

interface IBasketInfosProps {
  poNumber: string;
  data?: BasketInfo;
  basketIndex: number;
  getTotalQtyBySkuCode: (skuCode: string) => number;
  disableContent: boolean;
}

const rowTitles: RowTitleProps[] = [
  { title: 'SKU ID' },
  { title: 'Name' },
  { title: 'UoM' },
  { title: 'Order QTY', isCentered: true },
  { title: 'QTY Picked', isCentered: true },
  { title: 'Mark as Passed', isCentered: true },
];

export const BasketInfos: React.FC<IBasketInfosProps> = ({
  poNumber,
  data,
  basketIndex,
  getTotalQtyBySkuCode,
  disableContent,
}) => {
  const [progressColorIdx, setProgressColorIdx] = useState<number>(0);
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState<boolean>(false);

  const handleOpenDialog = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    setIsOpenPhotoModal(true);
  };

  const handleCloseDialog = (): void => {
    setIsOpenPhotoModal(false);
  };
  const countCheckedItems = (): number => {
    if (data) {
      return data.items.reduce(
        (sum, item) => sum + (item.check_passed ? 1 : 0),
        0,
      );
    }
    return 0;
  };

  useEffect(() => {
    const countItemChecked = countCheckedItems();
    if (countItemChecked === 0) {
      setProgressColorIdx(0);
    } else if (countItemChecked === data?.items.length) {
      setProgressColorIdx(1);
    } else {
      setProgressColorIdx(2);
    }
  }, [data]);

  return (
    <>
      <Grid container marginBottom="16px" spacing={0}>
        <Grid item xs={12}>
          <S.Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore style={{ color: PRIMARY_GRAY }} />}
              aria-controls={`basket${data?.basket}-content`}
              id={`basket${data?.basket}-header`}
              style={{
                padding: '2px 12px 2px 12px',
                backgroundColor: '#D9D9D926',
              }}
            >
              <S.BasketInfoHeader>
                <div
                  data-testid={`checkingBasket-${basketIndex + 1}`}
                >{`BASKET ${data?.basket}`}</div>
                <S.ProgressSpan>
                  <Brightness1
                    style={{
                      fontSize: 14,
                      color: PROGRESS_ICON_COLOR[progressColorIdx],
                    }}
                  />{' '}
                </S.ProgressSpan>
                <S.VerticalLine />
                <S.TakePhotoSection onClick={handleOpenDialog}>
                  {data?.photo_url.length === 0 ? (
                    <Button data-testid={`takePhoto-${basketIndex + 1}`}>
                      <CameraAlt /> TAKE PHOTO
                    </Button>
                  ) : (
                    <Button data-testid={`viewPhoto-${basketIndex + 1}`}>
                      VIEW PHOTO
                    </Button>
                  )}
                </S.TakePhotoSection>
              </S.BasketInfoHeader>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              <BasketInfoTable
                poNumber={poNumber}
                rowTitles={rowTitles}
                data={data?.items}
                basketNumber={data?.basket || 0}
                basketIndex={basketIndex}
                getTotalQtyBySkuCode={getTotalQtyBySkuCode}
                isDisabled={disableContent}
              />
            </AccordionDetails>
          </S.Accordion>
        </Grid>
      </Grid>
      <UploadPhotoModal
        openModal={isOpenPhotoModal}
        onClose={handleCloseDialog}
        poNumber={poNumber}
        basketNumber={data?.basket || 0}
        photos={data?.photo_url || []}
        isDisabled={disableContent}
      />
    </>
  );
};
