import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, TextField } from '@mui/material';

import { createLoginHistory } from 'redux-stores/actions';
import { login, selectUser } from 'redux-stores/features/authSlice';
import { AppDispatch } from 'redux-stores/store';

import * as S from './StationNumberDialog.styles';

export const StationNumberDialog: React.FC = () => {
  const dispatch = useDispatch();
  const appDispatch = useDispatch<AppDispatch>();

  const [stationNumber, setStationNumber] = useState<number>(0);
  const user = useSelector(selectUser);

  const onFieldChange = (value: string): void => {
    if (value === '') {
      setStationNumber(0);
      return;
    }
    const valueAsNumber =
      typeof value === 'string' ? parseInt(value, 10) : (value as number);

    const stationNumberValue = valueAsNumber > 0 ? valueAsNumber : 0;
    setStationNumber(stationNumberValue);
  };

  const handleSubmit = (): void => {
    appDispatch(createLoginHistory(stationNumber));
    dispatch(login({ ...user, stationNumber }));
  };

  return (
    <Dialog
      onClose={() => {}}
      open={!user.stationNumber}
      style={{ whiteSpace: 'pre-line' }}
    >
      <S.TitleWrapper>
        <S.Title>Enter Station Number</S.Title>
      </S.TitleWrapper>
      <S.FieldRowWrapper style={{ textAlign: 'center' }}>
        <TextField
          placeholder="Station Number"
          variant="outlined"
          type="number"
          value={stationNumber !== 0 ? stationNumber : ''}
          onChange={(event) => onFieldChange(event.target.value)}
          style={{ width: '250px' }}
          inputProps={{
            'data-testid': `inputStation`,
          }}
        />
      </S.FieldRowWrapper>
      <S.FooterWrapper>
        <S.OKButton
          onClick={() => handleSubmit()}
          style={{ width: '250px', maxWidth: '250px' }}
          data-testid="btnStationSubmit"
        >
          Submit
        </S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};
