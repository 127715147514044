import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import RowActionButton from 'commons/components/RowActionButton/RowActionButton.component';
import {
  OrderListRowAction,
  OrderStatusEnum,
  PrintPDFTypeEnum,
} from 'commons/enums';
import { KoliInfoUI, OrderType } from 'commons/types';
import { IPackingTask } from 'commons/types/packingTask.interface';
import { printPDF } from 'commons/utils/printPackingTaskPDF.util';
import { ViewPhotoModal } from 'pages/dashboard/commons/components/ViewPhotoModal.component';
import { completePackingDashboard } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

const PackingRowActionBtn: React.FC<{
  orderType: string;
  packingTask: IPackingTask;
  selectedLocation?: number;
}> = ({ orderType, packingTask, selectedLocation }) => {
  const { company } = useSelector(
    (state: RootReducerInterface) => state.company,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = React.useState(false);
  const [koli, setKoli] = useState<KoliInfoUI[]>([]);
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState<boolean>(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const options: {
    label: string;
    key: PrintPDFTypeEnum | OrderListRowAction;
  }[] = [
    { label: 'Print Invoice & TTF', key: PrintPDFTypeEnum.INVOICE_TTF },
    { label: 'Print Shipping Label', key: PrintPDFTypeEnum.SHIPPING_LABEL },
  ];

  if (orderType === OrderType.PRECURSOR) {
    options.push({ label: 'Print E-SP', key: PrintPDFTypeEnum.ESP });
  }

  if (packingTask.picking_task.status === OrderStatusEnum.PACKING) {
    options.push({
      label: 'Move To Packed',
      key: OrderListRowAction.MOVE_TO_PACKED,
    });
  }

  options.push({
    label: 'View Basket Photo',
    key: OrderListRowAction.VIEW_BASKET_PHOTO,
  });
  useEffect(() => {
    const koliUI = packingTask.koli.map((k) => ({
      weight: k.weight.toString(),
    }));
    if (koliUI.length === 0) {
      koliUI.push({ weight: '1' });
    }
    setKoli(koliUI);
  }, [packingTask.koli]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuClick = (menuKey: PrintPDFTypeEnum | OrderListRowAction) => {
    switch (menuKey) {
      case PrintPDFTypeEnum.INVOICE_TTF:
      case PrintPDFTypeEnum.SHIPPING_LABEL:
      case PrintPDFTypeEnum.ESP:
        printPDF(packingTask, company, koli, menuKey, dispatch);
        break;
      case OrderListRowAction.MOVE_TO_PACKED:
        dispatch(
          completePackingDashboard({
            poNumber: packingTask.po_number,
            location: selectedLocation,
          }),
        );
        break;
      case OrderListRowAction.VIEW_BASKET_PHOTO:
        setIsOpenPhotoModal(true);
        break;
      default:
    }
  };

  return (
    <>
      <RowActionButton
        open={open}
        options={options}
        handleClose={handleClose}
        handleMenuClick={handleMenuClick}
        handleToggle={handleToggle}
      />
      <ViewPhotoModal
        openModal={isOpenPhotoModal}
        onClose={() => setIsOpenPhotoModal(false)}
        poNumber={packingTask.po_number}
        photos={packingTask.basket_infos.flatMap(
          (basketInfo) => basketInfo.photo_url,
        )}
      />
    </>
  );
};
export default PackingRowActionBtn;
